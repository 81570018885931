import { apiBaseUrl } from '@/utils/env'
import { HttpService } from '@/services/common/HttpService'
import { AxiosResponse } from 'axios'
import { TableViewParameters, TableViewResult } from '@/models/TableView'
import { Transaction } from '@/models/dto/Transaction'
import { ApiResult } from '@/models/dto'

const httpService: HttpService = new HttpService()

export default {
  tableView(
    params: TableViewParameters
  ): Promise<AxiosResponse<TableViewResult<Transaction>>> {
    const { sorts, filters, pageSize = 10, page = 1 } = params
    let query = `page=${page}&pageSize=${pageSize}`
    if (sorts) {
      query += `&${sorts}`
    }
    if (filters) {
      query += `&${filters}`
    }

    query = encodeURI(query)
    return httpService.get(
      `https://${apiBaseUrl()}/tables/billing-transactions?page=1&pageSize=10&sort%5B0%5D%5Bfield%5D=transactionId&sort%5B0%5D%5Bdir%5D=desc&shortHand=1`
      // TODO: Hardcoded for testing since a request without query params will return a 500.
      // `https://${apiBaseUrl()}/tables/billing-transactions?${query}`
    )
  },
  updateTransactionNote(
    transaction: Transaction
  ): Promise<AxiosResponse<ApiResult>> {
    const url = `https://${apiBaseUrl()}/transaction/updateNote`
    return httpService.patch(url, transaction)
  },

  void(id: number): Promise<AxiosResponse<ApiResult>> {
    const url = `https://${apiBaseUrl()}/transaction/void/${id}`
    return httpService.delete(url)
  },
}
