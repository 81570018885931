
import DateMixin from '@/mixins/DateMixin'
import Component, { mixins } from 'vue-class-component'
import { DataTableColumn } from '@/models/DataTableColumn'
import { EventBus } from '@/utils/eventBus'
import { RawLocation } from 'vue-router'
import { Transaction } from '@/models/dto/Transaction'
import { currencyFilter } from '@/utils/string'
import CUCollectionTable from '@/components/CUCollectionTable.vue'
import CUDataTableLink from '@/components/CUDataTableLink.vue'
import CompanySidebarDetail from '@/components/CompanySidebarDetail.vue'
import TransactionSidebarDetail from '@/components/TransactionSidebarDetail.vue'
import client from '@/services/transaction'
import sidebar from '@/store/modules/sidebar'
import auth from '@/store/modules/auth'

@Component({
  components: {
    CUCollectionTable,
  },
})
export default class TransactionList extends mixins(DateMixin) {
  tableView = client.tableView
  tableItemCount = 0
  search = ''

  handleUpdateCount(count: number): void {
    this.tableItemCount = count
  }

  handleSelectedTransaction(transaction: Transaction): void {
    sidebar.push({
      title: 'Transaction',
      component: TransactionSidebarDetail,
      props: { transaction },
    })
  }

  handleSelectedCompany(transaction: Transaction): void {
    const companyId = transaction.companyId
    if (companyId) {
      sidebar.push({
        component: CompanySidebarDetail,
        props: { companyId },
      })
    }
  }

  columns: DataTableColumn[] = [
    {
      _t_id: '8565dfd2-9ac1-499c-be83-4e9c7f6dd9bc',
      text: 'ID',
      value: 'transactionId',
      type: 'link',
      clickEvent: 'transactions:transaction-selected',
    },
    {
      _t_id: '625a65de-c213-4ff3-a46b-436b43f610c5',
      text: 'Type',
      value: 'transactionType',
    },
    {
      _t_id: '77a39573-ec6b-4fa1-93a9-3ae7f6c04f4f',
      text: 'Amount',
      value: 'amount',
      computedText: (row: Transaction): string => currencyFilter(row.amount),
    },
    {
      _t_id: '13b82646-a23f-4708-a90a-3eea394a69d8',
      text: 'Acc. Primary',
      value: 'account1',
    },
    {
      _t_id: '1ccdb69f-1068-4578-9455-ab1315a0239b',
      text: 'Acc. Secondary',
      value: 'account2',
    },
    {
      _t_id: '4e88fdd8-80d3-433d-a778-328dcef1961f',
      text: 'Created On',
      value: 'createdOn',
      computedText: (row: Transaction): string =>
        this.formatMediumDate(row.createdOn, { tz: auth.getUserTimeZone }),
    },
    {
      _t_id: 'df8026c8-cb79-4510-ab80-ba8436f21935',
      text: 'Created By',
      value: 'createdBy',
    },
    {
      _t_id: '47a53aee-ade6-4e60-84ca-63f9e2c2a0de',
      text: 'Res. ID',
      value: 'reservation/managedId',
      columnSlotComponent: CUDataTableLink,
      linkLocation: (row: Transaction): RawLocation => ({
        name: 'reservations.detail',
        params: { id: row.reservationId?.toString() },
      }),
    },
    {
      _t_id: '493db242-f454-4b28-9448-7ed13013b5b2',
      text: 'Company Name',
      value: 'companyName',
      type: 'link',
      clickEvent: 'transactions:company-selected',
    },
  ]

  mounted(): void {
    EventBus.$on(
      'transactions:transaction-selected',
      this.handleSelectedTransaction
    )
    EventBus.$on('transactions:company-selected', this.handleSelectedCompany)
  }

  beforeDestroy(): void {
    EventBus.$off(
      'transactions:transaction-selected',
      this.handleSelectedTransaction
    )
    EventBus.$off('transactions:company-selected', this.handleSelectedCompany)
  }
}
